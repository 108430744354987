(function ($) {
  Drupal.behaviors.mppSort = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $formatters = $('.mpp-sort__container');

      $formatters.each(function (i, obj) {
        var $formatter = $(obj);
        var $sort_links = $('li', $formatter);
        var $label = $('.mpp-sort__label', $formatter);

        // Close dropdown
        function close() {
          $formatter.removeClass('open');
          $('.mpp-sort__label', $formatter).removeClass('open');
          $('.mpp-sort__content').hide();
          $(document).trigger('mpp-filter-set--close');
        }

        $label.click(function () {
          event.preventDefault();
          $formatter.toggleClass('open');
          $('.mpp-sort__label', $formatter).toggleClass('open');
          $('.mpp-sort__content').toggle();

          if ($formatter.hasClass('open')) {
            $(document).trigger('mpp-filter-set--open');
            $(document).on('mouseup.clickoutside touchend.clickoutside', function (e) {
              var container = $formatter;

              // if the target of the click isn't the container nor a descendant of the container
              if (!container.is(e.target) && container.has(e.target).length === 0) {
                e.preventDefault();
                close();
                $(document).off('.clickoutside');
              }
            });
          } else {
            close();
          }
        });

        if (!$sort_links.hasClass('active')) {
          $sort_links.first().addClass('active');
        }

        $(document).on('mpp-sort:reset', function () {
          var $default_link = $sort_links.first();

          $default_link.click();
        });

        $sort_links.each(function (i, obj) {
          var $link = $(obj);

          $link.click(function (e) {
            e.preventDefault();

            $sort_links.removeClass('active');
            $link.addClass('active');

            var val = $link.data('sort');

            $(document).trigger('mpp-sort:selected', val);
          });
        });
      });
    },
    attached: false
  };
})(jQuery);
